import React from "react"
import HeaderNavContent from "./HeaderNavContent"
import { graphql, StaticQuery } from "gatsby"

const staticQuery = graphql`
  query {
    prismic {
      services: allServices(lang: "en-gb", sortBy: position_ASC) {
        edges {
          node {
            _meta {
              uid
              type
              lang
            }
            title
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(quality: 100, maxWidth: 720, maxHeight: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            category {
              ... on PRISMIC_Service_category {
                name
                order
              }
            }
          }
        }
      }
      servicesFr: allServices(lang: "fr-fr", sortBy: position_ASC) {
        edges {
          node {
            _meta {
              uid
              type
              lang
            }
            title
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(quality: 100, maxWidth: 720, maxHeight: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            category {
              ... on PRISMIC_Service_category {
                name
                order
              }
            }
          }
        }
      }
    }
    allSitePage {
      nodes {
        context {
          uid
          lang
        }
        path
      }
    }
  }
`

const HeaderNav = (props) => {
  return (
    <StaticQuery
      query={`${staticQuery}`}
      render={data => {
        return (
          <React.Fragment>
            <HeaderNavContent props={props} meta={props.meta} data={data}/>
          </React.Fragment>
        )
      }}
    />
  )
}

export default HeaderNav
