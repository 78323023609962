/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import sharingImage from "../resources/img/social/sharing.png"

const SEO = ({ description, lang, meta, fullTitle, title, image, path, data }) => {
  const { site } = data

  const websiteUrl = site.siteMetadata.siteUrl

  const metaDescription = description || site.siteMetadata.description
  const socialSharingImage = image || websiteUrl + sharingImage
  const url = websiteUrl + path
  const pageUrl = url.substr(-1) === "/" ? url.substr(0, url.length - 1) : url

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={fullTitle ? `%s` : `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: pageUrl,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: socialSharingImage,
        },
        {
          property: `fb:app_id`,
          content: `2243518289231235`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image:src`,
          content: socialSharingImage,
        },
      ]
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
}

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`
export default props => (
  <StaticQuery
    query={`${query}`}
    render={data => <SEO {...props} data={data}/>}
  />
)
