import React from 'react';
import styled from "styled-components"
import LogoImg from '../../resources/img/logos/logo.svg';
import breakpoints from "../../styles/breakpoints";

const LogoStyled = styled.img`
    height: 55px;
    width: 152px;
    padding: 18px 0 16px 33px;
    @media(min-width: ${breakpoints.lg}){
        padding: 21px 0 21px 0;
    }
    @media(min-width: ${breakpoints.xl}){
        padding: 21px 0 21px 33px;
    }
    @media(min-width: ${breakpoints.xxl}){
        padding: 21px 0 21px 0;
    }
`;

const Logo = () => {
  return <LogoStyled src={LogoImg} />;
}

export default Logo;