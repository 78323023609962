import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import ExternalLink from "./ExternalLink"

const Border = posed.div({
  hoverable: true,
  focusable: true,
  pressable: true,
  init: {
    height: "32px",
    width: "32px",
    rotate: 45,
    border: "1.5px solid rgba(157, 220, 226, 0)",
    scale: 1
  },
  hover: {
    height: "32px",
    width: "32px",
    rotate: 45,
    border: "1.5px solid rgba(157, 220, 226, 1)",
    scale: 1.2
  },
  press: {
    height: "32px",
    width: "32px",
    rotate: 45,
    border: "1.5px solid rgba(157, 220, 226, 1)",
    scale: 1.1
  },
  focus: {
    height: "32px",
    width: "32px",
    rotate: 45,
    border: "1.5px solid rgba(157, 220, 226, 1)",
    scale: 1.1
  }
});

const BorderStyled = styled(Border)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `;

const SocialLinksContainer = styled.div` 
    display: flex;
    flex-direction: row;
    @media (min-width: 768px) {
    }  
`;

const IconStyled = styled.img`
    height: 16px;
    width: 19px;
    transform: rotate(-45deg);
`;

const StlyedLinks = styled(ExternalLink)`
    margin-right: 25px;
`;

const SocialMediaLinks = ({data}) => {

  return (
    <SocialLinksContainer>
      {
        data.map((item) => {
          return (
            <StlyedLinks href={item.link} key={item.link} className={'styledLink'}>
              <BorderStyled>
                <IconStyled src={item.icon.url} alt={item.icon.alt}/>
              </BorderStyled>
            </StlyedLinks>
          )
        })
      }
    </SocialLinksContainer>);
};

export default SocialMediaLinks;
