const colors = {
  black: '#000',
  white: '#FFF',
  blue: '#006DC6',
  lightBlue: '#EEF6FF',
  darkBlue: '#070D49',
  green: '#53B3A8',
  grey: '#939BA6',
};

export default colors;