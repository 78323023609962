import {createGlobalStyle} from 'styled-components';
import reset from 'styled-reset';
import dmSansRegularWoff from '../resources/fonts/dmsans-regular-webfont.woff';
import dmSansRegularWoff2 from '../resources/fonts/dmsans-regular-webfont.woff2';
import dmSansMediumWoff from '../resources/fonts/dmsans-medium-webfont.woff';
import dmSansMediumWoff2 from '../resources/fonts/dmsans-medium-webfont.woff2';
import dmSansBoldWoff from '../resources/fonts/dmsans-bold-webfont.woff';
import dmSansBoldWoff2 from '../resources/fonts/dmsans-bold-webfont.woff2';
import jaggerRegularWoff from '../resources/fonts/jagger-regular.woff';
import jaggerRegularWoff2 from '../resources/fonts/jagger-regular.woff2';
import jaggerBoldWoff from '../resources/fonts/jagger-bold.woff';
import jaggerBoldWoff2 from '../resources/fonts/jagger-bold.woff2';
import fonts from "./fonts"
import colors from "./colors"

const fontFace = (fontFamily, woff, woff2) => {
    return `
      @font-face {
          font-family: '${fontFamily}';
          src: url(${woff2}) format('woff2'),
          url(${woff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }
    `;
};

const GlobalStyle = createGlobalStyle`
    ${fontFace('DM Sans Regular', dmSansRegularWoff, dmSansRegularWoff2)}
    ${fontFace('DM Sans Medium', dmSansMediumWoff, dmSansMediumWoff2)}
    ${fontFace('DM Sans Bold', dmSansBoldWoff, dmSansBoldWoff2)}
    ${fontFace('Jagger Regular', jaggerRegularWoff, jaggerRegularWoff2)}
    ${fontFace('Jagger Bold', jaggerBoldWoff, jaggerBoldWoff2)}

    ${reset}
    
    body {
      ${fonts.dmSansRegular};
      font-size: 15px;
      line-height: 23px;
      color: ${colors.darkBlue};
    }
`;

export default GlobalStyle;
