import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import GlobalStyle from "../styles/global"
import { GridThemeProvider } from "styled-bootstrap-grid"
import { ThemeProvider } from "styled-components"
import HeaderNav from "./core/HeaderNav"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import FooterBlock from "./core/Footer"
import "./i18n"
import styled from "styled-components"

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 0,
  },
  col: {
    padding: 0,
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1296,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
}

const PageContainer = styled.div`
  overflow: hidden;
`

const styledTheme = {}

const staticQuery = graphql`
  query layoutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }`
;

const Layout = ({ meta, children }) => (
  <StaticQuery
    query={`${staticQuery}`}
      render={data => {
    return (
      <React.Fragment>
        <ThemeProvider theme={styledTheme}>
          <GridThemeProvider gridTheme={gridTheme}>
            <GlobalStyle/>
            <HeaderNav meta={meta}/>
            <PageContainer>
              {children}
            </PageContainer>
            <FooterBlock/>
          </GridThemeProvider>
        </ThemeProvider>
      </React.Fragment>
    )
  }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
