import React, { Component } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import { Col, Row, Container } from "styled-bootstrap-grid"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import Logo from "./Logo"
import DropdownImg from "../../resources/img/icons/dropdown-icon.svg"
import BurgerMenuImg from "../../resources/img/icons/burger-menu-icon.svg"
import CrossIconImg from "../../resources/img/icons/cross-icon.png"
import ArrowDown from "../../resources/img/icons/arrow-down.svg"
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"
import GetStartedButton from "./GetStartedButton"
import posed from "react-pose"
import Img from "gatsby-image"
import linkResolver from "../../utils/linkResolver";
import {navigate} from "gatsby-link";

const MobileNav = styled.div`
    display: block;
    height: 90px;
    background: ${colors.white};
    border-bottom: 1px solid #D8D8D8;
    @media(min-width: ${breakpoints.lg}){
        display: none;
    }
`

const DesktopNav = styled.div`
    display: none;
    @media(min-width: ${breakpoints.lg}){
        background: white;
        border-bottom: 1px solid #D8D8D8;
        height: 97px;
        display: block;
    }
`

const NavbarStyled = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 500;
`

const NavStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 39px;
`

const NavParentMenuStyled = styled.span`
    display: flex;
    flex-direction: row;
    cursor: default;
    align-items: center;
    justify-content: center;
    color: ${colors.darkBlue};
    font-size: 15px;
    ${fonts.dmSansMedium};
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
`

const NavLinkStyled = styled(Link)`
    display: flex;
    flex-direction: row;
    cursor: default;
    align-items: center;
    justify-content: center;
    color: ${colors.darkBlue};
    font-size: 15px;
    ${fonts.dmSansMedium};
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
`

const NavLinkText = styled.div`
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    :after {
        content: '';
        border-bottom: 3px solid transparent;
        display: block;
        position: absolute;
        right: 3px;
        left: 0;
    }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        :after {
            content: '';
            padding-bottom: 33px;
            border-bottom: 3px solid ${colors.green};
            display: block;
            position: absolute;
            -webkit-tap-highlight-color: transparent;
        }
    }
`

const NavButtonStyled = styled.div`
    padding-top: 39px;
    width: 100%;
    text-align: right;
`

const LanguageDropdown = styled.select`
    vertical-align: middle;
    align-items: center;
    flex-direction: row;
    ${fonts.dmSansBold};
    color: ${colors.darkBlue};
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1.5px;
    border: none;
    cursor: pointer;
    background: url(${DropdownImg}) no-repeat right;
    background-size: 12px 8px;
    background-position-x: 25px;
    -webkit-appearance: none;
    margin-left: 10px;
    width: 50px;
    float: right;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        transition: .5s;
        :after {
            content: '';
            -webkit-tap-highlight-color: transparent;
        }
    }
    @media(min-width: ${breakpoints.lg}){
        float: none;
    }
`

const NavStyledMobile = styled.div`
    margin-right: 0;
    margin-left: auto;
    height: 89px;
    line-height: 89px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const BurgerIcon = styled.img`
    margin-right: 33px;
    margin-left: 22px;
    height: 18.4px;
    width: 18.4px;
`

const BurgerMenuButton = styled.button`
    border: none;
    outline: 0;
    background: transparent;
    display: inline-block;
`

const HiddenSmallScreen = styled.div`
    display: none;
    @media(min-width: 390px){
      display: inline-block;
    }
`

const DesktopNavOverlayAnimated = posed.div({
  desktopNavFrom: {
    height: 0,
    transition: {
      duration: 0,
    },
  },
  desktopNavTo: {
    height: "auto",
    transition: {
      duration: 250,
    },
  },
})

const DesktopNavOverlayContainer = styled(DesktopNavOverlayAnimated)`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
`

const DesktopNavOverlay = styled.div`
  width: 100%;
  max-width: ${breakpoints.xxl};
  background: ${colors.white};
  padding: 48px 145px 45px 143px;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  a {
   display: flex;
   clear: both;
   float: left;
  }
`

const OverlayContainer = styled(Container)`
  width: 100%;
  position: relative;
  background: ${colors.white};
  display: block;
  padding: 73px 0 60px 0;
  margin-bottom: 70px;
  @media(min-width: ${breakpoints.lg}){
      padding: 0;
  }
`

const MobileOverlayAnimated = posed.div({
  mobileNavFrom: {
    height: 0,
    transition: {
      duration: 0,
    },
  },
  mobileNavTo: {
    height: "auto",
    transition: {
      duration: 250,
    },
  },
})

const SubMenuOverlayAnimated = posed.div({
  subMenuFrom: {
    height: 0,
    transition: {
      duration: 0,
    },
  },
  subMenuTo: {
    height: "auto",
    transition: {
      duration: 250,
    },
  },
})


const MobileOverlayContainer = styled(MobileOverlayAnimated)`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
`

const MobileNavOverlay = styled.div`
    width: 100%;
    position: relative;
    box-sizing: border-box;
    overflow: scroll;
    height: 100vh;
    @media(min-width: ${breakpoints.lg}) {
      display: none;
    }
`

const SectionTitle = styled.div`
    color: ${colors.grey};
    ${fonts.dmSansRegular};
    font-size: 18px;
    letter-spacing: 0;
    padding-top: 25px;
    padding-bottom: 19px;
`

const NavText = styled.div`
    position: relative;
    cursor: pointer;
    display: flex;
    width: auto;
    -webkit-tap-highlight-color: transparent;
    line-height: 40px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        color: ${colors.green};
    }
`

const NavImage = styled(Img)`
    width: 360px;
    height: 239px;
`

const MobileLinkStyled = styled(Link)`
    text-decoration: none;
`

const MobileSubMenuContainer = styled.div``

const MobileNavText = styled.div`
    font-size: 24px;
    letter-spacing: -0.69px;
    line-height: 42px;
    ${fonts.jaggerRegular};
    border-bottom: 1px solid #D9D9D9;
    color: ${colors.darkBlue};
    margin-left: 33px;
    margin-right: 33px;
    padding-bottom: 8px;
    text-decoration: none;
    margin-bottom: 18px;

`

const SubmenuMobileNavText = styled(MobileNavText)`
    background: url(${ArrowDown}) no-repeat 98% center;
    background-size: 14px 14px;
`

const NavBottomSection = styled.div`
    padding-top: 32px;
    padding-left: 26.5px;
    padding-right: 33px;
`

const SubMenu = styled(SubMenuOverlayAnimated)`
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 0;
`

const SubMenuLink = styled(Link)`
    text-decoration: none;
`

const SubMenuSection = styled.div`
    margin-left: 33px;
    margin-bottom: 20px;
`

const SubMenuText = styled.div`
    color: ${colors.darkBlue};
    ${fonts.jaggerRegular};
    font-size: 15px;
    line-height: 30px;
`

const SubMenuTitle = styled.div`
    color: ${colors.grey};
    ${fonts.dmSansRegular};
    font-size: 18px;
    letter-spacing: 0;
    margin-bottom: 10px;
`

const WebsiteOverlay = styled.div`
    height: ${props => props.hovered ? "100vh" : 0};
    width: 100%;
    background-color: #00000099;
    position: fixed;
    z-index: 3;
`

class HeaderNavContent extends Component {
  state = {
    servicesLinkHovered: false,
    isMobileNavOpen: false,
    isSubMenuOpen: false,
    navImage: null,
  }

  openOverlay = () => this.setState(
    { servicesLinkHovered: true },
  )

  closeOverlay = () => this.setState(
    { servicesLinkHovered: false },
  )

  toggleMobileNav = () => {
    this.setState({
      isMobileNavOpen: !this.state.isMobileNavOpen,
    })
  }

  toggleSubMenu = () => {
    this.setState({
      isSubMenuOpen: !this.state.isSubMenuOpen,
    })
  }

  switchLanguage(e, path, alternatePaths) {
    const lang = e.target.value
    if (lang === this.props.meta.lang) {
      return
    }
    const alternative = this.props.meta.alternateLanguages.find(v => v.lang === lang)
    navigate(linkResolver(alternative))
    this.props.i18n.changeLanguage(lang === 'en-gb' ? 'en' : 'fr')
  }

  updateNavImage = (serviceData) => {
    let navImage

    if (serviceData) {
      navImage = serviceData.hero_imageSharp
    }

    this.setState({
      navImage: navImage,
    })
  }

  getPathname = () => {
    if (typeof window !== 'undefined') {
      return window.location.pathname
    }
  }

  render() {
    const { t, i18n } = this.props
    const { servicesLinkHovered, isMobileNavOpen, isSubMenuOpen } = this.state
    const { openOverlay, closeOverlay, toggleMobileNav, toggleSubMenu } = this
    const allSitePage = this.props.data.allSitePage.nodes
    let path = this.getPathname() || "/"

    if (path.length > 1 && path.endsWith("/")) {
      path = path.slice(0, -1);
    }

    const alternatePaths = allSitePage.find((page) => page.path === path).context;

    const prismic = this.props.data.prismic;
    const services = i18n.language === 'fr' ? prismic.servicesFr.edges : prismic.services.edges

    let categories = []
    let serviceLinks = {}
    let navImage = this.state.navImage

    services.map((serviceData, index) => {
      const node = serviceData.node
      const {name : category, order} = node.category;

      if (!serviceLinks[category]) {
        serviceLinks[category] = []

        categories.push({category, order})
      }

      serviceLinks[category].push(node)

      if (!navImage && index === 0) {
        navImage = node.hero_imageSharp
      }
    })

    categories.sort((a, b) => a.order - b.order);

    const lang = this.props.meta.lang
    return (
      <WebsiteOverlay hovered={servicesLinkHovered || isMobileNavOpen}>
        <NavbarStyled onMouseLeave={closeOverlay}>
          <MobileNav>
            <Container>
              <Row>
                <Link to={"/"}>
                  <Logo/>
                </Link>
                <NavStyledMobile>
                  <HiddenSmallScreen>
                    <GetStartedButton colour={"white"}>
                      {t("core.signIn")}
                    </GetStartedButton>
                  </HiddenSmallScreen>
                  <BurgerMenuButton onClick={toggleMobileNav}>
                    <BurgerIcon src={isMobileNavOpen ? CrossIconImg : BurgerMenuImg}/>
                  </BurgerMenuButton>
                </NavStyledMobile>
              </Row>
            </Container>
          </MobileNav>
          <DesktopNav>
            <Container>
              <Row>
                <Col lg={2}>
                  <Link to={"/"}>
                    <Logo/>
                  </Link>
                </Col>
                <Col lg={5}>
                  <NavStyled>
                    <NavParentMenuStyled onMouseEnter={openOverlay}>
                      <NavLinkText>
                        {t("nav.services")}
                      </NavLinkText>
                    </NavParentMenuStyled>
                    <NavLinkStyled to={"/mfo"} onMouseEnter={closeOverlay}>
                      <NavLinkText>
                        {t("nav.mfo")}
                      </NavLinkText>
                    </NavLinkStyled>
                    <NavLinkStyled to={"/news"} onMouseEnter={closeOverlay}>
                      <NavLinkText>
                        {t("nav.news")}
                      </NavLinkText>
                    </NavLinkStyled>
                    <NavLinkStyled to={"/contact"} onMouseEnter={closeOverlay}>
                      <NavLinkText>
                        {t("nav.contact")}
                      </NavLinkText>
                    </NavLinkStyled>
                  </NavStyled>
                </Col>
                <Col lg={5}>
                  <NavButtonStyled>
                    <GetStartedButton/>
                    <GetStartedButton colour={"white"}>
                      {t("core.signIn")}
                    </GetStartedButton>
                    <LanguageDropdown
                      defaultValue={lang}
                      onChange={e => this.switchLanguage(e, path, alternatePaths)}>
                      <option value={"en-gb"}>{t("language.english")}</option>
                      <option value={"fr-fr"}>{t("language.french")}</option>
                    </LanguageDropdown>
                  </NavButtonStyled>
                </Col>
              </Row>
            </Container>
          </DesktopNav>
          <DesktopNavOverlayContainer pose={servicesLinkHovered ? "desktopNavTo" : "desktopNavFrom"}>
            <DesktopNavOverlay>
              <OverlayContainer>
                <Row>
                  {categories.map(({category}, index) => {
                    return (
                      <Col key={index} lg={4}>
                        <SectionTitle>
                          {category}
                        </SectionTitle>
                        {serviceLinks[category].map((serviceData, index) => {
                          return (
                            <NavLinkStyled key={index}
                                           to={linkResolver(serviceData._meta)}
                                           onMouseEnter={() => this.updateNavImage(serviceData)}
                                           onMouseLeave={() => this.updateNavImage()}>
                              <NavText>
                                {serviceData.title}
                              </NavText>
                            </NavLinkStyled>
                          )
                        })}
                      </Col>
                    )
                  })}
                  <Col lg={4}>
                    <NavImage fluid={navImage.childImageSharp.fluid}/>
                  </Col>
                </Row>
              </OverlayContainer>
            </DesktopNavOverlay>
          </DesktopNavOverlayContainer>

          <MobileOverlayContainer pose={isMobileNavOpen ? "mobileNavTo" : "mobileNavFrom"}>
            <MobileNavOverlay>
              <OverlayContainer>
                <MobileSubMenuContainer onClick={toggleSubMenu}>
                  <SubmenuMobileNavText>
                    {t("nav.services")}
                  </SubmenuMobileNavText>
                </MobileSubMenuContainer>
                <SubMenu pose={isSubMenuOpen ? "subMenuTo" : "subMenuFrom"}>
                  {categories.map(({category}, index) => {
                    return (
                      <Col key={index} sm={12}>
                        <SubMenuSection>
                          <SubMenuTitle>
                            {category}
                          </SubMenuTitle>
                          {serviceLinks[category].map((serviceData, index) => {
                            return (
                              <SubMenuLink key={index}
                                           to={linkResolver(serviceData._meta)}
                                           onMouseEnter={() => this.updateNavImage(serviceData)}
                                           onMouseLeave={() => this.updateNavImage()}>
                                <SubMenuText>
                                  {serviceData.title}
                                </SubMenuText>
                              </SubMenuLink>
                            )
                          })}
                        </SubMenuSection>
                      </Col>
                    )
                  })}
                </SubMenu>
                <MobileLinkStyled to={"/mfo"}>
                  <MobileNavText>
                    {t("nav.mfo")}
                  </MobileNavText>
                </MobileLinkStyled>
                <MobileLinkStyled to={"/news"}>
                  <MobileNavText>
                    {t("nav.news")}
                  </MobileNavText>
                </MobileLinkStyled>
                <MobileLinkStyled to={"/contact"}>
                  <MobileNavText>
                    {t("nav.contact")}
                  </MobileNavText>
                </MobileLinkStyled>
                <NavBottomSection>
                  <GetStartedButton/>
                  <LanguageDropdown
                    defaultValue={lang}
                    onChange={this.switchLanguage}>
                    <option value={"en-gb"}>{t("language.english")}</option>
                    <option value={"fr-fr"}>{t("language.french")}</option>
                  </LanguageDropdown>
                </NavBottomSection>
              </OverlayContainer>
            </MobileNavOverlay>
          </MobileOverlayContainer>
        </NavbarStyled>
      </WebsiteOverlay>
    )
  }
}

export default withTranslation()(HeaderNavContent)
