import React, {Component} from 'react';
import styled from 'styled-components';
import {Row, Col, Container} from 'styled-bootstrap-grid';
import colors from "../../styles/colors"
import LogoImg from "../../resources/img/logos/cube-logo.svg"
import breakpoints from "../../styles/breakpoints"
import { Link } from "gatsby"
import fonts from "../../styles/fonts"
import SocialMediaLinks from "./SocialMediaLinks"
import MailchimpSubscribe from './MailchimpSubscribe';
import { graphql, StaticQuery } from "gatsby";
import get from "lodash/get"
import {withTranslation} from "react-i18next";

const BackgroundWhite = styled.div`
    background-color: ${colors.white};
    padding: 70px 33px 97px 33px;
    border-top: 1px solid #D8D8D8;
    @media(min-width: ${breakpoints.lg}){
        padding-top: 124px 0 156px 0;
    }
`;

const Logo = styled.img`
    display: none;
    @media(min-width: ${breakpoints.lg}){
        padding-left: 10px;
        display: block;
        height: 88px;
        width: 78px;
    }
`

const NavLinkStyled = styled(Link)`
    display: flex;
    cursor: default;
    color: ${colors.darkBlue};
    font-size: 15px;
    ${fonts.dmSansMedium};
    text-decoration: none;
    line-height: 32px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
`

const LinkText = styled.div`
    cursor:pointer;
`

const SignUpContainer = styled.div`
    margin-right: 33px;
    margin-top: 54px;
    @media(min-width: ${breakpoints.lg}){
        margin-right: 0;
        margin-top: 0;
    }
`

const mailChimpSignupUrl = process.env.MAILCHIMP_SIGNUP_URL;

if (!mailChimpSignupUrl) {
  throw new Error('The mailchimp signup URL must be provided')
}

const url = mailChimpSignupUrl;

class Footer extends Component {
  render() {
    const { data: {prismic: { footer }} } = this.props
    const footers = get(this, "props.data.prismic.footer.edges")

    const footerData = footers.find((ver) => {
      return ver.node._meta.lang.includes(this.props.i18n.language)
    }).node.body

    const firstRow = []
    const secondRow = []
    const socialMedia = []

    footerData.forEach((data) => {
      if (data.__typename === "PRISMIC_FooterBodyFirst_row") {
        firstRow.push(data)
      }
      if (data.__typename === "PRISMIC_FooterBodySecond_row") {
        secondRow.push(data)
      }
      if (data.__typename === "PRISMIC_FooterBodySocial_media") {
        socialMedia.push(data)
      }
    })

    return (
      <BackgroundWhite>
        <Container>
          <Row>
            <Col lg={2}>
              <Logo src={LogoImg}/>
            </Col>
            <Col lg={2} xs={6} sm={6}>
              {firstRow[0].fields.map((menu, index) => {
                return (
                  <NavLinkStyled to={menu.link} key={index}><LinkText>{menu.menu_title}</LinkText></NavLinkStyled>
                )
              })}
            </Col>
            <Col lg={4} xs={6} sm={6}>
              {secondRow[0].fields.map((menu, index) => {
                return (
                  <NavLinkStyled to={menu.link} key={index}><LinkText>{menu.menu_title}</LinkText></NavLinkStyled>
                )
              })}
            </Col>
            <Col lg={4} xs={12} sm={12}>
              <SignUpContainer>
                <MailchimpSubscribe url={url}/>
                <SocialMediaLinks data={socialMedia[0].fields}/>
              </SignUpContainer>
            </Col>
          </Row>
        </Container>
      </BackgroundWhite>
    )
  }
}

const staticQuery = graphql`
query footerQuery {
  prismic {
    footer: allFooters {
      edges {
        node {
          _meta {
            lang
          }
          body {
            ... on PRISMIC_FooterBodyFirst_row {
              type
              label
              fields {
                link
                menu_title
              }
            }
            ... on PRISMIC_FooterBodySecond_row {
              type
              label
              fields {
                link
                menu_title
              }
            }
            ... on PRISMIC_FooterBodySocial_media {
              type
              label
              fields {
                icon
                link
              }
            }
          }
        }
      }
    }
  }
}
`

const FooterBlock = (props) => (
  <StaticQuery
    query={`${staticQuery}`}
    render={data => <Footer {...props} data={data} />}
  />
)

export default withTranslation()(FooterBlock);
