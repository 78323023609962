import React, { Component } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import RightArrow from "../../resources/img/icons/right-arrow.svg"
import { withTranslation } from "react-i18next"

const SignUpEmail = styled.div`
    width: 100%;
    label {
        color: ${colors.darkBlue};
        ${fonts.jaggerBold};
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.5px;
        display: block;
    }
    input {
        display: block;
        max-width: 326px;
        width: 100%;
        border: none;
        padding: 12px;
        ${fonts.jaggerRegular};
        outline: none;
        color: ${colors.darkBlue};
        font-size: 14px;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            ${fonts.dmSansRegular};
            color: ${colors.grey};
            font-size: 13px;
            line-height: 18px;
            text-transform: none;
            letter-spacing: 1px;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: ${colors.grey};
            ${fonts.dmSansRegular};
            font-size: 13px;
            line-height: 18px;
            text-transform: none;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: ${colors.grey};
            ${fonts.dmSansRegular};
            font-size: 13px;
            line-height: 18px;
            text-transform: none;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: ${colors.grey};
            ${fonts.dmSansRegular};
            font-size: 13px;
            line-height: 18px;
            text-transform: none;
        }
        :-webkit-autofill {
            -webkit-text-fill-color: ${colors.darkBlue};
            ${fonts.dmSansRegular};
            color: ${colors.grey};
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 2px;
        }
        }
        }
    `

const SignUpButton = styled.button`
        background: url(${RightArrow}) no-repeat right;
        background-size: 14px 14px;
        border: none;
        cursor: pointer;
        transition: all 0.5s;
        margin: 0 10px 0 auto;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        &:hover,
        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
            outline: none;
            box-shadow: none;
            transform: rotate(360deg);
        }
`

const SuccessMessage = styled.div`
    height: 10px;
    font-size: 19px;
    color: ${colors.blue};
    text-align: left;
    line-height: 35px;
    margin-top: 25px;
`

const ErrorMessage = styled.div`
    height: 10px;
    font-size: 19px;
    color: ${colors.blue};
    text-align: left;
    line-height: 35px;
    margin-top: 25px;
    margin-bottom: 90px;
`

const InputContainer = styled.div`
    border: 1px solid #DEE2E8;
    border-radius: 3px;
    margin-top: 20px;
    display: flex;
    max-width: 326px;
`


class SignUpForm extends Component {

  state = {
    email: "",
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.submit()
    }
  }

  submit = () =>
    this.state.email &&
    this.state.email.indexOf("@") > -1 &&
    this.props.onSubmitted({
      EMAIL: this.state.email,
    })

  render() {
    const { t, status, message } = this.props
    const { handleKeyPress, submit } = this

    return (<div>
        <SignUpEmail>
          <label htmlFor="sign-up">{t("footer.signup.intro")}</label>
          <InputContainer>
            <input
              placeholder={t("footer.signup.emailAddress")}
              onKeyPress={handleKeyPress}
              onChange={(event) => this.setState({ email: event.target.value })}
              type="email"
              aria-label={t("footer.signup.email_address")}/>
            <SignUpButton aria-label="Subscribe" onClick={submit}/>
          </InputContainer>
        </SignUpEmail>
        {status === "success" ? (
          <SuccessMessage>
            {t("footer.signup.thankYou")}
          </SuccessMessage>
        ) : <div/>}
        {status === "error" ? (
          <ErrorMessage
            dangerouslySetInnerHTML={{ __html: message }}
          />
        ) : <div style={{ height: "45px" }}/>}
      </div>
    )
  }
}

export default withTranslation()(SignUpForm)
